<template>
  <div>
    <CommunityComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import CommunityComp from '@/components/desktop/Community/CommunityComp'

  export default {
    name: 'Community',
    props: ['mobile'],
    components: {
      CommunityComp
    },
  }
</script>
