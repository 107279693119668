<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <div v-bind:class="mobile ? 'contentMobile1' : 'content'">
      <v-row justify="center" no-gutters>
        <v-col v-bind:cols="mobile ? 10 : 6">
          <div class="text" v-html="$t('text1')"></div>
          <div class="textBlue" v-html="$t('text2')"></div>
        </v-col>
        <v-col cols="6" v-if="!mobile">
          <div class="imgFirstSection">
            <v-img
                :src="require('@/assets/picto/pictoPeople6.svg')"
                contain
                height="450"
            />
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
        <v-col cols="5" style="text-align: right" v-if="!mobile">
          <h3 class="subtitle" v-html="$t('subtitle1')"></h3>
          <div class="text" v-html="$t('text3')"></div>
          <h3 class="subtitle" style="padding-top:465px;" v-html="$t('subtitle3')"></h3>
          <div class="text" v-html="$t('text5')"></div>
        </v-col>
        <v-col v-bind:cols="mobile ? 1 : 2">
          <v-img
              :src="require('@/assets/picto/separator11.svg')"
              contain
              v-bind:height="mobile ? 1000 : 900"
          />
        </v-col>
        <v-col v-bind:cols="mobile ? 9 : 5">
          <h3 class="subtitle" v-html="$t('subtitle1')" v-if="mobile"></h3>
          <div class="text" v-html="$t('text3')" v-if="mobile"></div>
          <h3 class="subtitle" v-bind:style="mobile ? 'padding-top:140px;' : 'padding-top:300px;'" v-html="$t('subtitle2')"></h3>
          <div class="text" v-html="$t('text4')"></div>
          <h3 class="subtitle" style="padding-top: 120px;" v-html="$t('subtitle3')" v-if="mobile"></h3>
          <div class="text" v-html="$t('text5')" v-if="mobile"></div>
        </v-col>
        <v-col cols="12">
          <div class="btnContact">
            <v-btn rounded
                   v-bind:class="mobile ? 'btnContentMobile' : 'btnContent'"
                   height="65px"
                   dark
                   v-html="$t('textBtn')"
                   @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
                   color="#57939E">
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CommunityComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Communities",
    "text1": "Developing physical and sports activity for all is one of our main missions. <br> <br> This is why we are working with different communities to develop physical activities that are adapted and accessible to as many audiences as possible.",
    "text2": "<br> <strong> We are developing various activities to promote and develop the health of the population </strong>",
    "text3": "<br> <br> - Balance workshop <br> - Digital workshop <br> - Project to fight against isolation and sedentary lifestyle in nursing homes <br> - Active walking workshop <br> - etc ...",
    "text4": "<br> <br> - Soft gym <br> - Active gym <br> - Memory gym <br> - Active walking <br> - Nordic walking <br> - Yoga <br> - Pilate <br> - Aquagym <br > - Coastal walk <br> - Longe coast",
    "text5": "<br> <br> <br> - Conference on the benefits of daily physical activity <br> - Conference on the prevention of falls and on the improvement of balance <br> - Prevention day: evaluation of the form and advice",
    "subtitle1": "Subsidized workshops",
    "subtitle2": "Recurrent adapted physical activities",
    "subtitle3": "Conferences and awareness days",
    "textBtn": "Join our health sport network"
  },
  "fr": {
    "mainTitle": "Collectivités",
    "text1": "Développer l’activité physique et sportive pour tous est une de nos missions principales. <br><br> C’est pourquoi nous collaborons avec différentes collectivités afin de développer des activités physiques adaptées et accessibles à un maximum de publics.",
    "text2": "<br> <strong>Nous développons différentes activités afin de promouvoir et développer la santé de la population </strong>",
    "text3": "<br><br>  - Atelier équilibre<br>  - Atelier numérique<br>  - Projet de lutte contre l'isolement et la sédentarité dans les EHPAD<br>  - Atelier marche active<br>  - etc...",
    "text4": "<br><br>- Gym douce <br>- Gym active<br> - Gym mémoire<br> - Marche active <br>- Marche nordique<br> - Yoga<br> - Pilates<br> - Aquagym <br>- Marche côtière<br> - Longe côte",
    "text5": "<br><br><br>- Conférence sur les bienfaits de l'activité physique quotidienne<br> - Conférence sur la prévention des chutes et sur l'amélioration de l'équilibre<br> - Journée de prévention : évaluation de la forme et conseil",
    "subtitle1": "Les ateliers subventionnés",
    "subtitle2": "Les activités physiques adaptées récurrentes",
    "subtitle3": "Les conférences et journées de sensibilisation",
    "textBtn": "Rejoindre notre réseau sport santé"
  }
}
</i18n>

<style scoped>

@media screen and (max-width: 1500px) {
  .btnContent {
    margin-top: 40px;
    font-size: 18px;
    width: 470px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none !important;
  }

}
@media screen and (min-width: 1500px) {
  .btnContent {
    font-size: 18px;
    width: 470px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none !important;
  }

}

.btnContentMobile {
  font-size: 15px;
  width: 350px;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: none !important;
}

.contentMobile1 {
  font-size: 14px;
}

.secondSection {
  margin-top: -150px;
}

.secondSectionMobile {
  padding-top: 40px;
}

.content {
  padding-right: 200px;
  padding-left: 200px;
}

.text {
  color: #808080;
}

.subtitle {
  color: #57939E;
}

.textBlue {
  color: #1579A7;
}

.imgFirstSection {
  margin-right: 100px;
}

.btnContact {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
}

</style>
